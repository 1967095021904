












































































































import { Vue, Component } from 'vue-property-decorator'
import { isVestcasa } from '@/shareds/utils'
import {
	EditarConfiguracaoDaContaUseCase,
	FindConfiguracaoDaContaUseCase,
} from '@/usecases'
import { ConfiguracaoDaConta } from '@/models/ConfiguracaoDaConta'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'

@Component({
	components: {
		CampoDecimal,
	},
})
export default class TelaDeConta extends Vue {
	carregando = true
	salvando = false
	erro: null | Error = null

	configuracaoDaConta: ConfiguracaoDaConta = {
		id: '',
		requerTabelaDePrecoNasLojas: false,
		pesquisaProdutoNaVenda: false,
		vincularClientesComLoja: false,
		eans: 7,
		medida: 6,
		casasNumericas: 4,
	}

	isVestcasa = isVestcasa
	editarConfiguracaoDaContaUsecase = new EditarConfiguracaoDaContaUseCase()
	findConfiguracaoDaContaUseCase = new FindConfiguracaoDaContaUseCase()

	avatar = !isVestcasa
		? require('@/assets/almode-avatar.png')
		: require('@/assets/vestcasa-avatar.png')

	async created() {
		try {
			this.carregando = true
			const buscaconfiguracaoDaConta =
				await this.findConfiguracaoDaContaUseCase.find()
			this.configuracaoDaConta.requerTabelaDePrecoNasLojas =
				buscaconfiguracaoDaConta.requerTabelaDePrecoNasLojas
			this.configuracaoDaConta.pesquisaProdutoNaVenda =
				buscaconfiguracaoDaConta.pesquisaProdutoNaVenda
			this.configuracaoDaConta.vincularClientesComLoja =
				buscaconfiguracaoDaConta.vincularClientesComLoja
			this.configuracaoDaConta.id = buscaconfiguracaoDaConta.id
			this.configuracaoDaConta.eans =
				buscaconfiguracaoDaConta.eans <= 0 ? 7 : buscaconfiguracaoDaConta.eans
			this.configuracaoDaConta.medida =
				buscaconfiguracaoDaConta.medida <= 0
					? 6
					: buscaconfiguracaoDaConta.medida
			this.configuracaoDaConta.casasNumericas =
				buscaconfiguracaoDaConta.casasNumericas <= 0
					? 4
					: buscaconfiguracaoDaConta.casasNumericas
		} catch (error: any) {
			this.erro = error
		} finally {
			this.carregando = false
		}
	}

	async salvar() {
		try {
			this.salvando = true
			await this.editarConfiguracaoDaContaUsecase.save(this.configuracaoDaConta)
			AlertModule.setSuccess('Configurações salvas com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}
}
